var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Table Simple Component",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSimple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-table-simple', {
    staticClass: "rounded-bottom mb-0",
    attrs: {
      "hover": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "light"
    }
  }, [_c('b-tr', [_c('b-th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" Region ")]), _c('b-th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Clothes ")]), _c('b-th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" Accessories ")])], 1), _c('b-tr', [_c('b-th', [_vm._v("Country")]), _c('b-th', [_vm._v("City")]), _c('b-th', [_vm._v("Trousers")]), _c('b-th', [_vm._v("Skirts")]), _c('b-th', [_vm._v("Dresses")]), _c('b-th', [_vm._v("Bracelets")]), _c('b-th', [_vm._v("Rings")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v(" Belgium ")]), _c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" Antwerp ")]), _c('b-td', [_vm._v("56")]), _c('b-td', [_vm._v("22")]), _c('b-td', [_vm._v("43")]), _c('b-td', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" 72 ")]), _c('b-td', [_vm._v("23")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" Gent ")]), _c('b-td', [_vm._v("46")]), _c('b-td', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" 18 ")]), _c('b-td', [_vm._v("50")]), _c('b-td', [_vm._v("61")]), _c('b-td', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" 15 ")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" Brussels ")]), _c('b-td', [_vm._v("51")]), _c('b-td', [_vm._v("27")]), _c('b-td', [_vm._v("38")]), _c('b-td', [_vm._v("69")]), _c('b-td', [_vm._v("28")])], 1), _c('b-tr', [_c('b-th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" The Netherlands ")]), _c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" Amsterdam ")]), _c('b-td', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" 89 ")]), _c('b-td', [_vm._v("34")]), _c('b-td', [_vm._v("69")]), _c('b-td', [_vm._v("85")]), _c('b-td', [_vm._v("38")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" Utrecht ")]), _c('b-td', [_vm._v("80")]), _c('b-td', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" 12 ")]), _c('b-td', [_vm._v("43")]), _c('b-td', [_vm._v("36")]), _c('b-td', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" 19 ")])], 1)], 1), _c('b-tfoot', [_c('b-tr', [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "7",
      "variant": "secondary"
    }
  }, [_vm._v(" Total Rows: "), _c('b', [_vm._v("5")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }