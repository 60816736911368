var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Row select support",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeRowSelectSupport) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-body', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Selection mode:",
      "label-cols-md": "3",
      "label-align-sm": "right"
    }
  }, [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "label": "title",
      "options": _vm.modes
    },
    model: {
      value: _vm.selectMode,
      callback: function callback($$v) {
        _vm.selectMode = $$v;
      },
      expression: "selectMode"
    }
  })], 1)], 1), _c('b-table', {
    ref: "selectableTable",
    staticClass: "mb-0",
    attrs: {
      "selectable": "",
      "select-mode": _vm.selectMode,
      "items": _vm.items,
      "fields": _vm.fields,
      "responsive": ""
    },
    on: {
      "row-selected": _vm.onRowSelected
    },
    scopedSlots: _vm._u([{
      key: "cell(avatar)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.value
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.status[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.status[0][data.value]) + " ")])];
      }
    }])
  }), _c('b-card-body', {
    staticClass: "demo-inline-spacing pt-0"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.selectAllRows
    }
  }, [_vm._v(" Select all ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.clearSelected
    }
  }, [_vm._v(" Clear selected ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.selectThirdRow
    }
  }, [_vm._v(" Select 3rd row ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.unselectThirdRow
    }
  }, [_vm._v(" Unselect 3rd row ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }