var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Table Light Component",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLight) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-table-lite', {
    attrs: {
      "hover": "",
      "items": _vm.items
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }