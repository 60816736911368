var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Sticky columns and headers",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSticky) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-body', [_c('b-form-checkbox', {
    attrs: {
      "inline": ""
    },
    model: {
      value: _vm.stickyHeader,
      callback: function callback($$v) {
        _vm.stickyHeader = $$v;
      },
      expression: "stickyHeader"
    }
  }, [_vm._v(" Sticky header ")]), _c('b-form-checkbox', {
    attrs: {
      "inline": ""
    },
    model: {
      value: _vm.noCollapse,
      callback: function callback($$v) {
        _vm.noCollapse = $$v;
      },
      expression: "noCollapse"
    }
  }, [_vm._v(" No border collapse ")])], 1), _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "sticky-header": _vm.stickyHeader,
      "no-border-collapse": _vm.noCollapse,
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "head(id)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Row ID ")])];
      },
      proxy: true
    }, {
      key: "head()",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(scope.label) + " ")])];
      }
    }, {
      key: "cell(avatar)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "src": data.value
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.status[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.status[0][data.value]) + " ")])];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(data.value))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }