var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('table-basic'), _c('table-style-options'), _c('table-row-col-style'), _c('table-responsive'), _c('table-formatter-callback'), _c('table-custom-render'), _c('table-sticky-columns-headers'), _c('table-rowdetails-support'), _c('table-row-select-support'), _c('table-kitchen-sink'), _c('table-light-weight'), _c('table-simple')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }