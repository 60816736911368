var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Formatter callback",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFormatterCallback) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_c('b-link', {
          attrs: {
            "href": "#".concat(data.value.replace(/[^a-z]+/i, '-').toLowerCase())
          }
        }, [_vm._v(" " + _vm._s(data.value) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }